.skills-section {
  text-align: center;
  padding: 2em;
  background: #f4f6f8;
  font-family: Arial, sans-serif;
}

.skills-section h1 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 1.5em;
}

.skills-type  h2 {
  color: #666;
}

.skills-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5em;
}

.skill-component {
  background-color: #fff;
  padding: 1.5em;
  border-radius: 10px;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.skill-component:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.skill-component h3 {
  font-size: 1.6rem;
  color: #333;
  margin-top: 0.5em;
}

.skill-component p {
  font-size: 1.2rem;
  color: #666;
  margin: 0.5em 0 1em;
}

.skill-level {
  display: flex;
  justify-content: center;
  gap: 0.3em;
}

.skills-type {
  margin-bottom: 180px;
}

.skills-type h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 20px;
}

.bubble {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e0e0e0;
  transition: background 0.3s;
}

.bubble.active {
  background: #0078d4;
}

.bubble.active:hover {
  background: #005bb5;
}

.skill-level .bubble:not(.active):hover {
  background: #ccc;
}

.skill-component svg,
.skill-component .fa-icon {
  color: #0078d4;
  transition: color 0.3s;
}

.skill-component:hover svg,
.skill-component:hover .fa-icon {
  color: #005bb5;
}
