.contact-section {
  padding: 50px 0;
  text-align: center;
  background-color: #f4f4f9;
}

.contact-section h1 {
  font-size: 6rem;
  color: #333;
  margin-bottom: 30px;
}

#contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1100px;
  width: 100%;
  padding: 0 15px;
  gap: 20px;
}

.contact-left, .contact-right {
  flex: 1;
  margin: 20px;
}

.contact-left h1.sub-title {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #555;
}

.contact-left p {
  font-size: 1.5rem;
  color: #666;
  margin: 10px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.social-icons a {
  color: #555;
  transition: transform 0.3s, color 0.3s;
}

.social-icons a:hover {
  transform: scale(1.1);
}

.cv_btn {
  margin-top: 20px;
}

.cv_btn a {
  color: #000;
  padding: 12px 18px;
  font-size: 1.5rem;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-decoration: none;
}

#contact .cv_btn a {
  color: #ff4742;
}

#contact .cv_btn a:hover {
  color: #fff;
}

.contact-right form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-right input, .contact-right textarea {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1.4rem;
  transition: border-color 0.3s;
}

.contact-right input:focus, .contact-right textarea:focus {
  border-color: #333;
}

.contact-right button.btn {
  padding: 12px 20px;
  border: none;
  background-color: #ff6f61;
  color: #fff;
  font-size: 1.6rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.contact-right button.btn:hover {
  background-color: #ff3b28;
  transform: translateY(-2px);
}

.confirmation-message {
  margin-top: 20px;
  padding: 15px;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  font-size: 1.4rem;
  text-align: center;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  
  .contact-left, .contact-right {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
}
