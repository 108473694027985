.project-details {
    padding: 70px;
    background-color: #f9f9f9;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.project-details img {
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    margin: 30px 0;
    transition: transform 0.3s ease-in-out;
}

.project-details img:hover {
    transform: scale(1.02);
}

.project-details h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.project-details p {
    font-size: 1.6rem;
    line-height: 1.6;
    max-width: 800px;
    margin-bottom: 30px;
    padding: 0 20px;
}

.btn-primaire {
    display: inline-block;
    padding: 12px 25px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #ff4742;
    color: #fff;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-bottom: 30px;
}

.btn-primaire:hover {
    background-color: #ff2e26;
    color: #333;
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Boutons de téléchargement */
.btn-download {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 5px;
    transition: background-color 0.3s;
}

.btn-download:hover {
    background-color: #45a049;
}

/* Bouton désactivé */
.btn-disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    pointer-events: none;
}

/* Projets mobiles - Réduction de la hauteur des images */
.project img.mobile {
    object-fit: cover; /* Assure que l'image reste centrée dans son cadre */
    height: 50%; /* Réduction de la hauteur */
    max-height: 250px; /* Hauteur maximale définie */
    width: 100%; /* Conserve la largeur de l'image */
    border-radius: 8px; /* Coins arrondis pour un look plus moderne */
    margin-bottom: 15px; /* Espacement avec le contenu en dessous */
}


.mobile {
    max-width: 250px !important;
}

.project-video {
    margin: 30px 0;
    text-align: center;
}

.project-video video {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.project-unavailable {
    color: #ff4742;
    font-weight: bold;
    margin-top: 20px;
}


@media (min-width: 992px) {
    .project-details h1 {
        font-size: 4.5rem;
    }
    .project-details p {
        font-size: 1.4rem;
    }
}

@media (min-width: 768px) {
    .project-details h1 {
        font-size: 4rem;
    }
    .project-details p {
        font-size: 1.6rem;
    }
}

@media (min-width: 576px) {
    .project-details h1 {
        font-size: 3rem;
    }
}
