.custom-cursor{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF0000;
    pointer-events: none;
    z-index: 99999;
    transition:  transform 0.3s, opacity 0.3s;
  }
  
  .custom-cursor-before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    background-color: rgba(255, 0, 0, 0.3);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: .2s ease;
    opacity: 1;
  }