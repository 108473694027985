/* Styles pour le carousel et les projets en vedette */

.carousel h3 {
    font-size: 3rem;
}

.carousel h4 {
    font-size: 1.5rem;
    text-decoration: underline;
}

/* Styles pour les indicateurs du carousel */
.carousel-indicators {
    margin-top: 20px; /* Ajusté pour une meilleure apparence */
}

.carousel-indicators li {
    background-color: #ffffff; /* Couleur de la pastille inactive */
    width: 15px;
    height: 15px;
}

.carousel-indicators .active {
    background-color: #ff0000; /* Couleur de la pastille active */
}

/* Styles pour les icônes de navigation */
.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: #ff0000;
    width: 40px;
    height: 40px;
}

/* Styles pour les images du carousel */
.carousel img {
    width: 100%;
    max-height: 600px;
    object-fit: cover; /* Assure que les images couvrent bien l'espace */
}

@media (max-width:768px) {
    .carousel h3 {
        font-size: 1.5rem; /* Réduire la taille de police */
        margin-bottom: 5px;
      }

      .carousel h4 {
        font-size: 1.3rem; /* Réduire la taille de police */
        margin-bottom: 5px;
      }
}

/* Section de texte du projet */
.text-project-section {
    position: absolute; /* Utilisation de absolute pour mieux lier au carousel */
    bottom: 20%;
    right: 10%;
    width: 30%; /* Utilisation de pourcentage pour un meilleur responsive */
    color: white;
    background-color: rgba(70, 0, 0, 0.8); /* Ajout de transparence */
    padding: 20px;
    box-sizing: border-box;
}


@media (max-width: 886px) {
    .text-project-section {
        width: 80%;
        right: 10%;
        bottom: 5%;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        width: 20px;
        height: 20px;
    }
}

/* Media query pour ajuster la section texte sur les petits écrans */
@media (max-width: 768px) {
    .text-project-section {
        width: 80%;
        right: 10%;
        bottom: 5%;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        width: 20px;
        height: 20px;
    }
}

/* Adaptation pour écrans de 425px de large */
@media (max-width: 425px) {
    .text-project-section h2 {
        font-size: 1.8rem; /* Réduire la taille des titres */
    }
    .text-project-section p {
        font-size: 1rem; /* Adapter la taille du texte de paragraphe */
        line-height: 1.4; /* Ajuster l'espacement pour améliorer la lisibilité */
        padding: 10px; /* Réduire le padding latéral */
    }

    .text-project-section {
        width: 90%; /* Ajuster la largeur de la section de texte */
        right: 5%;
        bottom: 5%;
        padding: 5px; /* Réduire le padding */
    }

    img {
        min-height: 154.97px;
    }
}

/* Adaptation pour écrans de 375px de large */
@media (max-width: 375px) {
    .text-project-section h2 {
        font-size: 1.6rem; /* Réduire encore la taille du titre */
    }
    .text-project-section p {
        font-size: 0.9rem; /* Ajuster encore la taille du texte de paragraphe */
        line-height: 1.3; /* Réduire l'espacement */
        padding: 0 10px; /* Réduire davantage le padding latéral */
    }
}

