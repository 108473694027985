.projects {
    padding: 50px 0;
    background-color: #f7f7f7; /* Ajouter un fond clair pour le contraste */
}

.projects h1 {
    font-size: 4rem;
    margin-bottom: 40px;
    text-align: center;
    color: #333;
}

.projects-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.project {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px;
    padding: 20px;
    max-width: 300px; /* Limiter la largeur pour une meilleure responsivité */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s; /* Ajouter une transition pour l'ombre */
    background-color: #ffffff; /* Fond blanc pour chaque projet */
    text-align: center; /* Centrer le texte */
}

.project:hover {
    transform: translateY(-5px); /* Léger décalage vers le haut au survol */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

.project img {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Coins arrondis pour les images */
    margin-bottom: 15px; /* Espacement entre l'image et le texte */
}

.project h2 {
    font-size: 2.5rem; /* Taille réduite pour une meilleure lisibilité */
    margin: 10px 0;
    color: #333;
    text-transform: uppercase;
    font-weight: bold;
}

.project h3 {
    font-size: 1.5rem;
    color: #777; /* Couleur grise pour le type de projet */
}

.project p {
    font-size: 1.2rem;
    color: #555; /* Couleur légèrement plus claire pour la description */
    margin: 10px 0;
}

.btn-primaire {
    background: #FF4742;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s; /* Ajouter une transition au bouton */
    text-align: center;
    text-decoration: none; /* Enlever la soulignure pour un lien */
}

.btn-primaire:hover {
    background-color: #ff6260; /* Couleur légèrement plus claire au survol */
    transform: scale(1.05); /* Légère augmentation de la taille au survol */
}

/* Boutons de téléchargement */
.btn-download {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 5px;
    transition: background-color 0.3s;
}

.btn-download:hover {
    background-color: #45a049;
}

/* Bouton désactivé */
.btn-disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    pointer-events: none;
}

/* Projets mobiles - Réduction de la hauteur des images */
.project img.mobile {
    object-fit: cover;
    height: 80%; /* Réduit la hauteur de moitié */
    max-height: 500px; /* Limite la hauteur maximale */
    width: 100%; /* Garde la largeur intacte */
    border-radius: 8px;
}


