.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #f7f7f7;
  color: white;
}

.btn{
  font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    outline: 0;
    padding: 12px 14px;
    cursor: pointer;
}



.btn-primaire{
    background: #FF4742;
    border: 1px solid #FF4742;
    padding: 8px 15px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.btn-primaire:hover {
    background-color: initial;
    background-position: 0 0;
    color: #FF4742;
  }
  
  .btn-primaire:active {
    opacity: .5;
  }


