@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

:root{
  --red-theme-background-color-black : #1d1d1d;
  --red-theme-main-color : #FF0000;
  --red-theme-font-color-text : #ffffff;
  --red-theme-nav-background-color : #181818;
  
}
* {
  box-sizing: border-box; 
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body{
  background: var(--red-theme-background-color-black);
  color: var(--red-theme-main-color);
  height: 100%;
  cursor: none;
}

/* -------- custom scrollbar -------- */
::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
   background: black;
}

::-webkit-scrollbar-thumb{
  background: #FF4742;
}

#root{
  height: 100%;
}

 /* ---------------------------Buble anmations--------------------------- */
 ul .dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: white;
  position: absolute;
  top: 20%;
  right: 20%;
}


.animate{
    position: absolute;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      list-style: none;
      z-index: -1;
}

.animate li{
  text-decoration: none;
  height: 60px;
  width: 60px;
  border: 2px solid skyblue;
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 4s linear infinite;
  opacity: 0.2;
}

.animate li:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.animate li:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.animate li:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.animate li:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.animate li:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.animate li:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.animate li:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.animate li:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.animate li:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.animate li:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
} 
.animate li:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.animate li:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.animate li:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.animate li:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.animate li:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}

/* Animation floating */
.floating {
  animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }    
}

.cv_btn{
  position: relative;
  display: inline-block;
  padding: 30px 61px;
  border-radius: 4px;
  color: #03e9f4;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  margin: 25px;
  font-family: "Roboto", sans-serif;
  filter: hue-rotate(0deg);
  border: 2px solid #d2bdff;
  transition: all 0.1s linear;

}

.cv_btn{
  color: #fff;
  text-align: center;
}

.cv_btn:hover{
  border: 1px solid transparent;
}

.cv_btn:hover span{
  position: absolute;
  display: block;
}

.cv_btn:hover span:nth-child(1){
  filter: hue-rotate(0deg);
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent, #3a86ff);
  animation: animate1 1s linear infinite;
}

@keyframes animate1 {
  0%  { 
        left: -100%;
      }
      50%,
      100% 
      {
        left: 100%;
      }
}

.cv_btn:hover span:nth-child(2){
  filter: hue-rotate(60deg);
        top: -100%;
        right: 0;
        width: 3px;
        height: 100%;
        background: linear-gradient(180deg, transparent, #3a86ff);
        animation: animate2 1s linear infinite;
        animation-delay: 0.25s;
}

@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.cv_btn:hover span:nth-child(3){
  filter: hue-rotate(120deg);
  bottom: 0;
  right: 0;
  width: 100%;

  background: linear-gradient(270deg, transparent, #3a86ff);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes animate3 {
  0% {
    right: -100%;
    height: 3px;
  }
  50%,
  100% {
    height: 2px;
    right: 100%;
  }
}

.cv_btn:hover span:nth-child(4){
  filter: hue-rotate(300deg);
        bottom: -100%;
        left: 0;
        width: 3px;
        height: 100%;
        background: linear-gradient(360deg, transparent, #3a86ff);
        animation: animate4 1s linear infinite;
        animation-delay: 0.75s;
}

@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}