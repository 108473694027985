/* ----- Styles généraux ----- */
body {
  background-color: #121212;
  color: #eeeeee;
  font-family: Arial, sans-serif;
}

/* ----- Conteneur de lumières animées ----- */
.lights-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.light {
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 0.8;
  background-color: #ff4742;
  box-shadow: 0 0 10px #ff4742, 0 0 20px #ff4742;
}

/* ----- Arrière-plan ----- */
.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)), url("../../assets/images/bg-texture-1.jpeg");
  background-size: cover;
  background-position: center;
  /* filter: blur(1px); */
}

.section-Home {
  text-align: center;
}

.section-Home .cv_btn a {
  color: #ff4742;
}
.section-Home .cv_btn :hover{
  color: #fff;
}

.home-title {
  font-size: 4rem;
  color: #ff4742;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  animation: fadeIn 2s ease-in-out;
}

/* ----- Typing Animation ----- */
.typed-text {
  font-size: 2.5rem;
  color: #eeeeee;
  margin-top: 10px;
}

/* ----- Button Download CV ----- */
.cv_btn {
  position: relative;
  padding: 15px 30px;
  border: 2px solid #ff4742;
  margin-top: 30px;
  display: inline-block;
  transition: all 0.4s ease;
}

.cv_btn:hover {
  background-color: #ff4742;
  color: #121212;
  box-shadow: 0px 4px 15px rgba(255, 71, 66, 0.5);
}

.download-link {
  color: #eeeeee;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}

/* ----- About Section ----- */
.about-part {
  padding: 80px 0;
  margin: 0 50px 50px 50px;
  background-color: #1c1c1c;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.about-part img {
  max-width: 30%;
}

@media (max-width: 1175px) {
  .about-part {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {

  .about-part img {
    max-width: 70%;
  }
}

.about-part p {
  font-size: 2rem;
  margin: 0 30px;
}

.about-part button {
  margin: 30px 0;
  font-size: 1.5rem;
}

.about-part span {
  font-size: 4.5rem;
  font-weight: bold;
}


.featured-projects-section {
  text-align: center;
  background-color: #1c1c1c;
  padding: 50px;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.featured-projects-section h1 {
  font-size: 3rem;
  color: #ff4742;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .featured-projects-section {
    flex-direction: column;
    justify-content: center;
  }

  .featured-projects-section img {
    max-width: 100%;
  }


  .span-subtitle-feature-project {
    font-size: 0.9rem; /* Adapter la taille du sous-titre */
    padding: 3px 8px; /* Réduire le padding pour le sous-titre */
  }

  .featured-projects-section p {
    font-size: 0.8rem; /* Taille de texte ajustée pour le contenu */
    padding: 0 10px;
  }

  .btn-primaire {
    padding: 12px 25px; /* Rendre le bouton plus compact */
    font-size: 1rem;
  }
}

.span-subtitle-feature-project {
  font-size: 1rem;
  color: #eeeeee;
  background-color: #ff4742;
  padding: 5px 10px;
  border-radius: 5px;
}

.btn-primaire {
  background-color: #ff4742;
  color: #121212;
  padding: 15px 30px;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.3s ease;
}

.btn-primaire:hover {
  background-color: #ff1e1a;
  box-shadow: 0px 5px 15px rgba(255, 71, 66, 0.4);
}

/* ----- Animations ----- */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Adaptation pour écrans de 425px de large */
@media (max-width: 425px) {
  .home-title {
      font-size: 2.5rem; /* Réduire la taille du titre principal */
  }
  .typed-text {
      font-size: 1.5rem; /* Réduire la taille du texte animé */
  }
  .cv_btn {
      padding: 10px 20px; /* Rendre le bouton CV plus compact */
  }
  .download-link {
      font-size: 1rem; /* Adapter la taille de la police du lien */
  }
  .about-part {
      padding: 40px 20px;
      margin: 20px; /* Réduire la marge autour de la section */
  }
  .about-part p {
      font-size: 1.5rem;
      margin: 0 10px;
  }
  .about-part span {
      font-size: 3rem; /* Réduire la taille des titres importants */
  }
  .featured-projects-section h1 {
      font-size: 2rem; /* Adapter la taille du titre des projets en vedette */
  }
  .span-subtitle-feature-project {
      font-size: 0.8rem; /* Ajuster la taille du sous-titre */
  }
  .btn-primaire {
      padding: 10px 20px;
      font-size: 1rem; /* Réduire la taille du bouton primaire */
  }
  .project-details h1 {
      font-size: 2rem;
  }
  .project-details p {
      font-size: 1rem;
      padding: 0 10px;
  }
  .project-details img {
      max-width: 100%;
      margin: 20px 0;
  }
}

/* Adaptation pour écrans de 375px de large */
@media (max-width: 375px) {
  .home-title {
      font-size: 2rem; /* Réduire encore la taille du titre */
  }
  .typed-text {
      font-size: 1.2rem; /* Réduire la taille du texte animé */
  }
  .cv_btn {
      padding: 8px 15px; /* Rendre le bouton encore plus compact */
  }
  .download-link {
      font-size: 0.9rem; /* Ajuster la taille de la police du lien */
  }
  .about-part {
      padding: 30px 10px;
      margin: 10px;
  }
  .about-part p {
      font-size: 1.2rem;
  }
  .about-part span {
      font-size: 2.5rem;
  }
  .featured-projects-section h1 {
      font-size: 1.8rem;
  }
  .span-subtitle-feature-project {
      font-size: 0.7rem;
      padding: 2px 5px;
  }
  .btn-primaire {
      padding: 8px 15px;
      font-size: 0.9rem;
  }
  .project-details h1 {
      font-size: 1.8rem;
  }
  .project-details p {
      font-size: 0.9rem;
  }
  .project-details img {
      max-width: 100%;
      margin: 15px 0;
  }
}
