/* --------------- Général --------------- */

body {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.9;
  /* background-color: #f9f9f9; */
  font-size: 1.3rem;
}

h1 {
  font-size: 6rem;
  font-weight: 700;
}

p {
  font-size: 1.4rem;
}

/* --------------- Sections --------------- */

section {
  padding: 120px 0;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.about-section p {
  color: #000;
}

.about-section h1 {
  color: #000;
}

/* --------------- Histoire Section --------------- */

.histoire {
  padding: 100px 0;
  background: #f1f1f1;
}

.histoires {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .histoires {
    flex-direction: row;
    justify-content: space-between;
  }
}

.histoires img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.infosHistoires {
  max-width: 600px;
  margin: 20px;
  text-align: center;
}

.infosHistoires p {
  font-size: 1.5rem;
  line-height: 2;
}

/* --------------- En Résumé --------------- */

.En-Resume {
  background-color: #ff3c3c;
  color: #fff;
  padding: 120px 20px;
}

/* ---------------sections Qui suis je ---------------*/

.En-Resume {
  padding: 150px 0;
  background-color: #ff3c3c;
}

@media(min-width: 1500px) {
  .En-Resume {
      padding: 300px 0;
  }
}

.quiSuisJe h1 {
  margin: 30px 0;
  color: #000;
  font-size: 3rem;
  font-weight: bold;
}

@media(min-width: 1500px) {
  .quiSuisJe h1 {
      margin: 50px 0;
      font-size: 3rem;
  }
}

.cards {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  margin: 10px;
}

@media(min-width: 1500px) {
  .card {
      margin: 20px;
  }
}

.card h1 {
  font-size: 2rem;
  margin: 10px;
}

@media(min-width: 1500px) {
  .card h1 {
      font-size: 4rem;
      margin: 20px;
  }
}

.card p {
  font-size: 1rem;
}

@media(min-width: 1500px) {
  .card p {
      font-size: 2rem;
  }
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
}

@media(min-width: 1500px) {
  .flip-card {
      width: 300px;
      height: 300px;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #6c0000;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-back {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #d54747;
  color: white;
  text-align: center;
  padding: 10px;
  transform: rotateY(180deg);
}

.flip-card-back h1 {
  font-size: 2.3rem;
  font-weight: bold;
}

.flip-card-back p {
  font-size: 1.3rem;
}

/* --------------- Objectifs Section --------------- */

.Objectifs {
  background-color: #333;
  color: #fff;
  padding: 140px 20px;
}

.Objectifs h1 {
  color: #fff;
}

.contentObjectif p {
  font-size: 1.6rem;
  line-height: 2;
  color: #fff;
}

/* ---------------section ProjectNow ---------------*/

.ProjectNow {
  padding: 100px 0;
  background-color: #f9f9f9;
}

.ProjectNow .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}



.ProjectNow p {
  color: #555;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  max-width: 800px;
  margin: 20px auto;
}

.ProjectNow ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ProjectNow li {
  font-size: 1.1rem;
  color: #333;
  background-color: #e9ecef;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 5px;
}

@media (min-width: 768px) {
  .ProjectNow p {
    font-size: 1.5rem;
  }

  .ProjectNow h1 {
    font-size: 6rem;
  }

  .ProjectNow li {
    font-size: 1.2rem;
  }
}

@media (min-width: 1500px) {
  .ProjectNow {
    padding: 150px 0;
  }

  .ProjectNow h1 {
    font-size: 4rem;
  }

  .ProjectNow p {
    font-size: 1.8rem;
  }
}


